@font-face {
  font-family: 'Neutraface';
  src: url('./Neutraface\ Text\ Bold.otf');
}

.App {
  text-align: center;
  background-color: #1d1d1d;

}

.App-logo {
  height: 15vmin;
  pointer-events: none;
  z-index: 1;
  margin-bottom: 3em;
}

.logo {
  font-family: 'Neutraface';
  font-size: 10vw;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.1em;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
  margin-bottom: 0.5em;
  /* animation: rainbow-fade 10s infinite linear; */
}

.tiny {
  height: 15vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .spin {
    /* alternate betweem spin and spin-alternate */
    animation: spin 10s infinite linear;
    filter: brightness(0) invert(1);
  }
}

.App-header {
  background-color: #5865f2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rainbow-fade {
  100%,0%{
    color: rgb(255,0,0);
  }
  8%{
    color: rgb(255,127,0);
  }
  16%{
    color: rgb(255,255,0);
  }
  25%{
    color: rgb(127,255,0);
  }
  33%{
    color: rgb(0,255,0);
  }
  41%{
    color: rgb(0,255,127);
  }
  50%{
    color: rgb(0,255,255);
  }
  58%{
    color: rgb(0,127,255);
  }
  66%{
    color: rgb(0,0,255);
  }
  75%{
    color: rgb(127,0,255);
  }
  83%{
    color: rgb(255,0,255);
  }
  91%{
    color: rgb(255,0,127);
  }
}

h2 {
  color: #61dafb;
}


.button {
  background-color: #61dafb;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.5rem;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  color: #000;
  font-family: 'Neutraface';
}

.button-outlined {
  background-color: transparent;
  border: 2px solid #61dafb;
  color: #61dafb;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.5rem;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  color: #000;
  font-family: 'Neutraface';
}