.Main {
    display: flex;
    flex-direction: column;
}

.header {
    background-color: #5865f2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: calc(10px + 1.5vmin);
    color: white;
}

.input {
    width: 35%;
    min-width: 200px;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.botConfig {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

button {
    background-color: #61dafb;
    border: none;
    color: rgb(0, 0, 0);
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    font-family: 'Neutraface';
}

.botConsole {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px 20px;
    margin: 10px 5em;
    max-height: 500px;
    min-height: 500px;
    background-color: #191919; /* to make it look like a terminal */
    overflow-y: scroll;
    justify-content: end;
    bottom: 0;
}

.botConsole > p {
    margin: 0;
    padding: 0;
    color: #fff;
    /* terminals need a monospace font */
    font-family: 'Courier New', Courier, monospace;

}

.spacedText {
    letter-spacing: .2em !important;
}

.WelcomeMsg {
    color: #fff;
    font-size: 1.5em;
    font-family: 'Neutraface';
}

.commandsList {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 50%;
}

.commandsList > h1 {
    display: flex;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.commandGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 10px;
}

.commandGroup input {
    width: 50%;
    padding: 12px 20px;
    margin: 8px 6px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.commandGroup button {
    background-color: #61dafb;
    border: none;
    color: white;
    padding: 7.5px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 2em;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    color: #000;
    font-family: 'Neutraface';
}

.consoleConfig {
   /* 2x2 grid that is centered */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 10px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  

.consoleConfigItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 10px;
}

.consoleConfigItem p {
    margin: 0;
    padding: 0;
    color: #fff;
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.2em;
    font-weight: bold;

}

#console.info {
    color: #fff;
}

#console.warn {
    color: rgb(238, 255, 0);
}

#console.error {
    color: rgb(255, 0, 0);
}

#console.success {
    color: rgb(0, 255, 0);
}

#console.debug {
    color: rgb(106, 245, 180);
}

.voiceStuff {
    display: flex;
    flex-direction: row;
}

.voiceStuff > input {
    width: 50%;
    padding: 12px 20px;
    margin: 8px 6px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}